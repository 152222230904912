import React, { Component } from "react";
import { Helmet } from "react-helmet";

const PageMeta = (props) => {
  let {
    title = "",
    description = "",
    robotValue = "index, follow",
    type = "article",
  } = props;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name={description} />
        <meta name="robots" content={robotValue} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {/* <meta property="og:type" content={type} /> */}
        {/* <meta property="og:url" content="PERMALINK" /> */}
        {/* <meta property="og:site_name" content="SITE NAME" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {/* <meta name="twitter:image" content="LINK TO IMAGE" /> */}
        {/* <meta name="twitter:site" content="@USERNAME" /> */}
        {/* <meta name="twitter:creator" content="@USERNAME" /> */}
      </Helmet>
    </>
  );
};

export default PageMeta;
