import React, { useEffect } from "react";
import PageMeta from "../../components/page-meta/page-meta";
import SharedScripts from "../../shared-scripts";
import "./landing-page.scss";

const LandingPage = () => {
    useEffect(() => {
        SharedScripts.exectute();
    }, []);
    return (
        <div className="landingPageWrapper">
            <PageMeta title="Landing page for hair2stay" description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, quidem!" />
            

            
            <header className="landingPageHeader">
                {/* Nav */}
                <nav id="nav" className="navbar nav-transparent">
                    <div className="container">
                        <div className="navbar-header">
                            {/* Logo */}
                            <div className="navbar-brand">
                            <a href="/">
                                <img className="logo" src={require("../../assets/img/true-color-h2s-web.png")} alt="logo" width="100px" height="auto" />
                                <img className="logo-alt" src={require("../../assets/img/true-color-h2s-web.png")} alt="logo" width="150px" height="auto" />
                            </a>
                            </div>
                            {/* /Logo */}
                        </div>
                    </div>
                </nav>
                {/* /Nav */}


                {/* <ul className="ct-socials">
                    <li>
                        <a href="https://www.facebook.com/hair2stay.in/" target="_blank"> <i className="fa fa-facebook" /> </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/hair2stay.in/" target="_blank"> <i className="fa fa-instagram" /> </a>
                    </li>
                </ul> */}
            </header>






            <div className="heroSection" style={{ backgroundImage: `url(${require("../../assets/img/banner-bg.png")})` }}>
                <div className="container">
                    <div className="heroContent">
                        <div className="left">
                            <h1 className="heroTitle main-heading">
                                Guranteed head <br /> <span className="big blue-text">full of hair</span> in just <span className="blue-text">60 mins</span>
                            </h1>
                            
                            <div className="desc">
                                <div className="descItem">
                                    <img src={require("../../assets/img/checkCircle.svg")} alt="" />
                                    <span className="blue-text">Natural</span>
                                </div>
                                <div className="descItem">
                                    <img src={require("../../assets/img/checkCircle.svg")} alt="" />
                                    <span className="blue-text">Non-Surgical</span>
                                </div>
                                <div className="descItem">
                                    <img src={require("../../assets/img/checkCircle.svg")} alt="" />
                                    <span className="blue-text">100% Real Human Hair</span>
                                </div>
                                <div className="descItem">
                                    <img src={require("../../assets/img/checkCircle.svg")} alt="" />
                                    <span className="blue-text">0% EMI Option Available</span>
                                </div>
                            </div>

                            <div className="buttonWrapper">
                                <a href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Book a Free Demo</a>
                            </div>
                        </div>



                        <div className="right">
                            <div className="formWrapper">
                                <div className="formHeader">
                                    <div className="top">Location:</div>
                                    <div className="bottom">Juhu, Vile Parle (W), Mumbai</div>
                                </div>

                                <div className="formContent">
                                    <div className="top">
                                        Save Big on Hair Patch Treatment - <span>50% Off Now</span>!
                                    </div>
                                    <form action="https://www.zooty.in/manage/leads/create_leads_from_api/?api_secret_access_key=user_8365933_qwvlfrnjj" method="post">
                                        <div className="form-field labels-are-inside">
                                            <div>
                                            <label htmlFor="name">
                                                <input id="name" type="text" name="name" placeholder="Your Name" required="required"/>
                                            </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                            <label htmlFor="phone">
                                                <input id="phone" type="number" min="5000000000" max="9999999999" name="phone" placeholder="Your Phone" required="required"/>
                                            </label>
                                            </div>
                                        </div>
                                        <div>
                                            {/* <!-- IMPORTANT: TO CHANGE THE BUTTON NAME - Change the value here--> */}
                                            <input type="submit" name="go" value="Send message"/>
                                        </div>
                                        {/* <!-- IMPORTANT: TO CHANGE THE THANKYOU PAGE - Change the value here--> */}
                                        <input type="hidden" name="lp" value="https://lp.zooty.in/thank-you-hair2stay/"/>
                                        <input type="hidden" name="source" className="lsource" value="Google"/>
                                        {/* <!-- IMPORTANT: SERVICE NAME - Change the value here--> */}
                                        <input type="hidden" name="for" value="Hair Patch"/>
                                        {/* <!-- IMPORTANT: FOR WHOM THE AD IS RUNNING - UPDATE PROFILE ID HERE--> */}
                                        <input type="hidden" name="provider" value="14292"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>






            <div className="blueStripSection">
                <div className="container">
                    <div className="inner">
                        <img src={require("../../assets/img/inv-color-h2s-web.png")} alt="" />
                        <span>is the right choice</span>
                    </div>
                </div>
            </div>



            
            <div className="servicesSection">
                <div className="container">
                    <div className="section-header text-center">
                        <h1 className="title">Services that we offer</h1>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="serviceCardItem">
                                <div className="imageWrapper">
                                    <img src="https://lh3.googleusercontent.com/9l89lJJq0LZGZfc-KgrwwFzf1LrygrsnJmPRA7L4YP9rCsrOvV515xWiFWvxHhyuYxdCZTZevMY_62vzS5IDLbGgO0-sTXbXotE=w399" alt="" />
                                </div>

                                <div className="serviceCardText">
                                    <div className="desc"><span>50%</span> off on hair patch</div>
                                    <span href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Click to get a free demo</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="serviceCardItem">
                                <div className="imageWrapper">
                                    <img src="https://lh3.googleusercontent.com/vogS4DE6cVkoLdXVYqRd5PK7pytJYWe-4PGIvk2CpwBtOxJjclrxJZ2Gkm7WrifT-fWEo7OYFxzfArHIYx2bg3Km1n3I2WgkJ_Y=w399" alt="" />
                                </div>

                                <div className="serviceCardText">
                                    <div className="desc"><span>50%</span> off on hair toppers</div>
                                    <span href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Click to get a free demo</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="serviceCardItem">
                                <div className="imageWrapper">
                                    <img src="https://lh3.googleusercontent.com/fxThHKP3W1aSx-1kcZF3SFMEn2HOD7oJzOm74YQg4p_u9iJsb9a26Nq0d9NDQzbKpORPo4MS60sNWLTZiKS2XgJIFEBbe5ZMxA=w399" alt="" />
                                </div>

                                <div className="serviceCardText">
                                    <div className="desc"><span>50%</span> off on hair replacement</div>
                                    <span href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Click to get a free demo</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="successStoriesSection">
                <div className="container">
                    <div className="section-header text-center">
                        <h1 className="title">Our Success Stories</h1>
                    </div>

                    <img src={require("../../assets/img/before-after-4.png")} alt="" className="w-100" />

                    <div className="buttons">
                        <a href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Transform Your Look</a>
                    </div>
                </div>
            </div>






            <div className="uspSection">
                <div className="container">
                    <div className="section-header text-center">
                        <h1 className="title">USP</h1>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="icon"><span>5K</span></div>
                            <div className="iconLabel">Happy <br /> Customers</div>
                        </div>

                        <div className="col-md-4">
                            <div className="icon"><span>10+</span></div>
                            <div className="iconLabel">Years of <br /> Experience</div>
                        </div>

                        <div className="col-md-4">
                            <div className="icon"><span>0%</span></div>
                            <div className="iconLabel">EMI Option <br /> Available</div>
                        </div>
                    </div>

                    <div className="buttons">
                        <a href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Book your appointment now</a>
                    </div>
                </div>
            </div>






            <div className="whyChooseUsSection">
                <div className="container">
                    <div className="section-header text-center">
                        <h1 className="title white-text">Why Choose <span>Hair Patch</span> at <img src={require("../../assets/img/inv-color-h2s-web.png")} alt="" /></h1>
                    </div>


                    <div className="row">
                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/icon-1.png")} alt="" /></div>
                            <div className="iconLabel">Proven <br />Result</div>
                        </div>

                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/icon-2.png")} alt="" /></div>
                            <div className="iconLabel">Natural <br />Look & Feel</div>
                        </div>

                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/icon-3.png")} alt="" /></div>
                            <div className="iconLabel">Durability <br />Longevity</div>
                        </div>

                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/icon-4.png")} alt="" /></div>
                            <div className="iconLabel">Discreet <br />Comfortable</div>
                        </div>
                    </div>

                    <div className="buttons">
                        <a href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Book a Free Demo</a>
                    </div>
                </div>
            </div>






            <div className="fourIconsSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/chair.png")} alt="" /></div>
                            <span className="iconTitle">Personalized Luxurious Rooms</span>
                        </div>
                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/sanitiser.png")} alt="" /></div>
                            <span className="iconTitle">Excellent <br /> Hygiene</span>
                        </div>
                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/security.png")} alt="" /></div>
                            <span className="iconTitle">Customer's Privacy & Discretion</span>
                        </div>
                        <div className="col-md-3">
                            <div className="icon"><img src={require("../../assets/img/hair-cut.png")} alt="" /></div>
                            <span className="iconTitle">Expert <br /> Hairstylists</span>
                        </div>
                    </div>

                    <div className="buttons">
                        <a href="#" className="main-btn" data-toggle="modal" data-target="#popupModal">Book Customizable Hair Patches</a>
                    </div>
                </div>
            </div>




            <div className="testimonialSection">
                <div className="container">
                    <div className="section-header text-center">
                        <h1 className="title">Testimonials</h1>
                    </div>

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="imgWrapper"><img src={require("../../assets/img/testi1.jpg")} alt="" className="w-100" /></div>
                            <div className="imgWrapper"><img src={require("../../assets/img/testi2.jpg")} alt="" className="w-100" /></div>
                            <div className="imgWrapper"><img src={require("../../assets/img/testi3.jpg")} alt="" className="w-100" /></div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>







            <div className="faqSection">
                <div className="container">
                    <div className="section-header text-center">
                        <h1 className="title white-text">Frequently Asked Question</h1>
                    </div>


                    <div className="row">
                        <div className="col-md-6">
                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">What is hair replacement and how does it work?</div>
                                    <div className="ans">Hair replacement refers to the use of wigs, hairpieces, and hair systems to cover areas of the scalp affected by hair loss. It works by attaching a hair system to the scalp, creating the illusion of fuller, natural-looking hair.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">Is hair replacement a permanent solution for hair loss?</div>
                                    <div className="ans">Life depends on the model you choose and the upkeep of the hair skin. There are different models available with us and once you meet with our expert she will be able to guide you on which model suits your lifestyle the best.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">Can hair replacement be customised to match my hair color and style?</div>
                                    <div className="ans">Yes, hair replacement can be customised to match your hair color, style, and texture. This allows for a natural-looking, seamless appearance.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">Are there any side effects or risks associated with hair replacement?</div>
                                    <div className="ans">There are no side effects or risks associated with hair replacement, but improper application or maintenance can lead to skin irritation or damage to the hair system.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">How much does hair replacement cost?</div>
                                    <div className="ans">The cost of hair replacement varies depending on the type of hair system chosen and the customisation required for the hair system. Our experts can take you through the various options available.</div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">How long does a hair replacement procedure take?</div>
                                    <div className="ans">The length of a hair replacement procedure depends on the type of hair system chosen, but typically takes anywhere from 30 minutes to a couple of hours.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">How often will I need to get my hair replaced?</div>
                                    <div className="ans">Hair systems typically need to be replaced every few months to maintain the appearance of full hair.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">Can I get hair replacement for just a certain area or part of my head?</div>
                                    <div className="ans">Yes, hair system can be customised to cover just a certain area or part of the head, providing natural-looking results.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">Can I still wear hats or swim after getting hair replacement?</div>
                                    <div className="ans">Yes, you can still wear hats and swim after getting hair replacement, but it is important to take proper care of your hair system to maintain its appearance and longevity.</div>
                                </div>
                            </div>


                            <div className="listItem">
                                <div className="left"><img src={require("../../assets/img/checkCircle.svg")} alt="" /></div>

                                <div className="right">
                                    <div className="ques">Are EMI or Instalment Options available?</div>
                                    <div className="ans">Yes. We do offer 0% Interest EMI options with Instant processing. To avail the same we just need your PAN Card & Aadhar Card details. In addition to this, you can also opt for EMI on your Credit Card.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="hairPatchBannerSection">
                <img src={require("../../assets/img/hair-patch-banner.png")} alt="" className="w-100" />
            </div>







            <div className="reachUsSection">
                <div className="container">
                    <div className="section-header text-center">
                        <h1 className="title white-text">Reach Us</h1>
                    </div>



                    <div className="row">
                        <div className="col-md-4 left">
                            <div className="item">
                                <div className="label">Location</div>
                                <div className="details">Juhu, Mumbai, India</div>
                            </div>
                            <div className="item">
                                <div className="label">Contact us</div>
                                <div className="details">+91 98201 99966</div>
                            </div>
                            {/* <div className="item">
                                <div className="label">Email</div>
                                <div className="details">hair2staymumbai@gmail.com</div>
                            </div> */}
                        </div>


                        <div className="col-md-8">
                            {/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.9962787504332!2d72.82891997497819!3d19.107819182103306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9863fcdbf3f%3A0xb4cfaf6459a2f511!2sHair2Stay-Men&#39;s%2FWomen%20Salon%20in%20Juhu-Keratin%20treatment%20in%20Juhu-Hair%20Patch%20in%20Andheri%20West-Hair%20Salon%20in%20Juhu!5e0!3m2!1sen!2sin!4v1719391105949!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>




            <div className="footer">
                <footer id="footer" class="sm-padding bg-dark">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="footer-copyright">
                                    <p>Copyright © 2024 Hair2Stay, All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>





            {/********************************************* popup modal for lead */}
            <div class="modal fade" id="popupModal" tabindex="-1" role="dialog" aria-labelledby="popupModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div className="formWrapper">
                                {/* <div className="formHeader">
                                    <div className="top">Location:</div>
                                    <div className="bottom">Juhu, Vile Parle (W), Mumbai</div>
                                </div> */}

                                <div className="formContent">
                                    <div className="top">
                                        Save Big on Hair Patch Treatment - <span>50% Off Now</span>!
                                    </div>
                                    <form action="https://www.zooty.in/manage/leads/create_leads_from_api/?api_secret_access_key=user_8365933_qwvlfrnjj" method="post">
                                        <div className="form-field labels-are-inside">
                                            <div>
                                            <label htmlFor="name">
                                                <input id="name" type="text" name="name" placeholder="Your Name" required="required"/>
                                            </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                            <label htmlFor="phone">
                                                <input id="phone" type="number" min="5000000000" max="9999999999" name="phone" placeholder="Your Phone" required="required"/>
                                            </label>
                                            </div>
                                        </div>
                                        <div>
                                            {/* <!-- IMPORTANT: TO CHANGE THE BUTTON NAME - Change the value here--> */}
                                            <input type="submit" name="go" value="Send message"/>
                                        </div>
                                        {/* <!-- IMPORTANT: TO CHANGE THE THANKYOU PAGE - Change the value here--> */}
                                        <input type="hidden" name="lp" value="https://lp.zooty.in/thank-you-hair2stay/"/>
                                        <input type="hidden" name="source" className="lsource" value="Google"/>
                                        {/* <!-- IMPORTANT: SERVICE NAME - Change the value here--> */}
                                        <input type="hidden" name="for" value="Hair Patch"/>
                                        {/* <!-- IMPORTANT: FOR WHOM THE AD IS RUNNING - UPDATE PROFILE ID HERE--> */}
                                        <input type="hidden" name="provider" value="14292"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/********************************************* /popup modal for lead */}
        </div>
    )
}

export default LandingPage;