import React, { useEffect } from "react";
import PageMeta from "../../components/page-meta/page-meta";
import SharedScripts from "../../shared-scripts";
import "./error-not-found.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Error404 = () => {
    const history = useHistory();
    useEffect(() => {
        SharedScripts.exectute();
    }, []);

    return (
        <div className="errorWrapper text-center">
            <img src={require("../../assets/img/error.svg")} alt="" />

            <h1>Oops! Page not found!</h1>
            <h3>Seems like there's a typo in the URL</h3>

            <div className="buttons text-center">
                <span onClick={(e) => history.replace("/")} className="btn main-btn">Go back to home</span>
            </div>
        </div>
    )
}

export default Error404;