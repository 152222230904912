const MailScript = {
  exectute: () => {
    const { $, jQuery, axios } = window;
    $(document).ready(function () {
      $("#contactForm").submit(function (event) {
        event.preventDefault(); //
        var elements = this.elements;
        const name = elements[0].value;
        const phone = elements[1].value;
        const email = elements[2].value;
        const message = elements[3].value;

        const headers = {
          "Content-Type": "application/json",
          "X-Api-Key": "c90vrttb6okvtrgl4i5gXESanSNAcP",
          "X-Api-Secret": "qGsNQpuQrTyjqgHZrzrsOQWJWrD.WKirBcrTNEgKcJBrZHUykR",
        };

        jQuery.noConflict();
        jQuery("#myModal").modal();

        axios
          .post(
            "https://cors-proxy.debojyoti.dev/https://api.mailazy.com/v1/mail/send",
            {
              to: ["hair2staymumbai@gmail.com"],
              from: "sender@hair2stay.in",
              subject: `Website Contact Form Submission by ${name}`,
              content: [
                {
                  type: "text/plain",
                  value: `
                        Name: ${name}
                        Phone: ${phone}
                        Email: ${email}
                        Message: ${message}
                      `,
                },
              ],
            },
            {
              headers: headers,
            }
          )
          .then((response) => {})
          .catch((error) => {});
        //  Reset form
        elements[0].value = "";
        elements[1].value = "";
        elements[2].value = "";
        elements[3].value = "";
      });
    });
  },
};

export default MailScript;
