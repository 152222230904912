/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import PageMeta from "../../components/page-meta/page-meta";
import SharedScripts from "../../shared-scripts";

const SalonPage = () => {
  useEffect(() => {
    SharedScripts.exectute();
  }, []);
  return (
    <>
      <PageMeta title="Mens Salon for Grooming in Juhu" description="Personalised Individual Rooms for everyone. Bluetooth with 40 inch TV. Expert Hair Stylist.
Call us for an appointment !!" />
      <div>
        {/* Header */}
        <header id="home">
          {/* Background Image */}
          {/* <div class="bg-img" style="background-image: url('./img/bg-image.jpg');">
		</div> */}
          <div className="container">
            <div
              id="myCarousel"
              className="carousel slide bg-img"
              data-ride="carousel"
            >
              {/* Indicators */}
              <ol className="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to={0}
                  className="active"
                />
                <li data-target="#myCarousel" data-slide-to={1} />
                <li data-target="#myCarousel" data-slide-to={2} />
              </ol>
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item active">
                  <img
                    src="https://res.cloudinary.com/psdesignz4u/image/upload/v1654238599/Hair2Stay%20Website/new/banner-1_fbavmd.png"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="item">
                  <img
                    src="https://res.cloudinary.com/psdesignz4u/image/upload/v1654238597/Hair2Stay%20Website/new/banner-2_yomovp.png"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="item">
                  <img
                    src="https://res.cloudinary.com/psdesignz4u/image/upload/v1654238605/Hair2Stay%20Website/new/banner-3_opchux.png"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              {/* Left and right controls */}
              <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                <span><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="right carousel-control" href="#myCarousel" data-slide="next">
                <span><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
          {/* <div class="bg-img-mob" style="background-image: url('./img/bg-image-mob.jpg');">
			
		</div> */}
          <div className="container">
            <div
              id="myCarouselMobile"
              className="carousel slide bg-img-mob"
              data-ride="carousel"
            >
              {/* Indicators */}
              <ol className="carousel-indicators">
                <li
                  data-target="#myCarouselMobile"
                  data-slide-to={0}
                  className="active"
                />
                <li data-target="#myCarouselMobile" data-slide-to={1} />
                <li data-target="#myCarouselMobile" data-slide-to={2} />
              </ol>
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item active">
                  <img
                    src="https://res.cloudinary.com/psdesignz4u/image/upload/v1654238603/Hair2Stay%20Website/new/Mob-banner-1_ey1rbc.png"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="item">
                  <img
                    src="https://res.cloudinary.com/psdesignz4u/image/upload/v1654238603/Hair2Stay%20Website/new/Mob-banner-2_yq0kez.png"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="item">
                  <img
                    src="https://res.cloudinary.com/psdesignz4u/image/upload/v1654238605/Hair2Stay%20Website/new/Mob-banner-3_e5ovce.png"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              {/* Left and right controls */}
              <a className="left carousel-control" href="#myCarouselMobile" data-slide="prev">
                <span><i className="fa fa-chevron-left" aria-hidden="true"></i></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="right carousel-control" href="#myCarouselMobile" data-slide="next">
                <span><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
          {/* /Background Image */}
          {/* Nav */}
          <nav id="nav" className="navbar nav-transparent">
            <div className="container">
              <div className="navbar-header">
                {/* Logo */}
                <div className="navbar-brand">
                  <a href="/salon">
                    <img
                      className="logo"
                      src={require("../../assets/img/icon.png")}
                      alt="logo"
                      width="40px"
                      height="auto"
                    />
                    <img
                      className="logo-alt"
                      src={require("../../assets/img/inv-color-h2s-web-salon.png")}
                      alt="logo"
                      width="150px"
                      height="auto"
                    />
                  </a>
                </div>
                {/* /Logo */}
                {/* Collapse nav button */}
                <div className="nav-collapse">
                  <span />
                </div>
                {/* /Collapse nav button */}
              </div>
              {/*  Main navigation  */}
              <ul className="main-nav nav navbar-nav navbar-right salonNav">
                <li>
                  <a href="#about">Services</a>
                </li>
                {/* <li><a href="#portfolio">Portfolio</a></li>
					<li><a href="#service">Services</a></li>
					<li><a href="#pricing">Prices</a></li>
					<li><a href="#team">Team</a></li>
					<li class="has-dropdown"><a href="#blog">Blog</a>
						<ul class="dropdown">
							<li><a href="blog-single.html">blog post</a></li>
						</ul>
					</li> */}
                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
              {/* /Main navigation */}
            </div>
          </nav>
          {/* /Nav */}
          <ul className="ct-socials">
            <li>
              <a href="https://www.facebook.com/hair2stay.in/" target="_blank">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/hair2stay.in/" target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
          {/* home wrapper */}
          <div className="home-wrapper">
            <div className="container">
              <div className="row">
                {/* home content */}
                <div className="col-md-10">
                  <div className="home-content">
                    <img
                      className="logo-sm salon-logo-sm"
                      src={require("../../assets/img/inv-color-h2s-web-salon.png")}
                      alt
                    />
                    <h1 className="white-text main-heading">Men's Salon</h1>
                    <div className="row col-web">
                      <div className="col-lg-9 col-md-9 text-center">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              className="icon-desc"
                              src={require("../../assets/img/chair.png")}
                              alt
                            />
                            <br />
                            <span className="white-text icon-text">
                              Personal Rooms
                            </span>
                          </div>
                          <div className="col-md-3">
                            <img
                              className="icon-desc"
                              src={require("../../assets/img/tv.png")}
                              alt
                            />
                            <br />
                            <span className="white-text icon-text">TV</span>
                          </div>
                          <div className="col-md-3">
                            <img
                              className="icon-desc"
                              src={require("../../assets/img/hair-cut.png")}
                              alt
                            />
                            <br />
                            <span className="white-text icon-text">
                              Expert Stylists
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row col-mob">
                      <div className="col-sm-6">
                        <img
                          className="icon-desc"
                          src={require("../../assets/img/chair.png")}
                        />
                        <span className="white-text icon-text-sm">
                          Personal Rooms
                        </span>
                      </div>
                      <div className="col-sm-6">
                        <img
                          className="icon-desc"
                          src={require("../../assets/img/tv.png")}
                          alt
                        />
                        <span className="white-text icon-text-sm">TV</span>
                      </div>
                      <div className="col-sm-6">
                        <img
                          className="icon-desc"
                          src={require("../../assets/img/hair-cut.png")}
                          alt
                        />
                        <span className="white-text icon-text-sm">
                          Expert Stylists
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <button class="white-btn">Get Started!</button> */}
                  <button
                    className="main-btn salon-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "#contact";
                    }}
                  >
                    CONTACT US
                  </button>
                </div>
              </div>
              {/* /home content */}
            </div>
          </div>
          {/* /home wrapper */}
        </header>
        {/* /Header */}
        <div id="about" className="section md-padding">
          {/* Container */}
          <div className="container">
            {/* Row */}
            <div className="row">
              {/* Section header */}
              <div className="section-header text-center">
                <h2 className="title">OUR SERVICES</h2>
              </div>
              {/* /Section header */}
              <div className="service-web">
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-1.png")}
                    alt
                  />
                  <span>
                    <h4>Signature Hair Cut</h4>
                    <p>
                      Enjoy the H2S Signature haircut to perfection in your own
                      private lounge by our top stylist
                    </p>
                  </span>
                </div>
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-2.png")}
                    alt
                  />
                  <span>
                    <h4>Hair Styling + Shampoo + Blowdry</h4>
                    <p>
                      Our Classic 3-in-1 Combo, which never runs out of style
                    </p>
                  </span>
                </div>
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-3.png")}
                    alt
                  />
                  <span>
                    <h4>Signature Shave</h4>
                    <p>
                      Enjoy our signature shave, in our super luxurious barber
                      chairs while listening to your own music
                    </p>
                  </span>
                </div>
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-4.png")}
                    alt
                  />
                  <span>
                    <h4>Hair Colour</h4>
                    <p>
                      Catch up with favourite show on our large screen TV, while
                      we colour your hair
                    </p>
                  </span>
                </div>
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-5.png")}
                    alt
                  />
                  <span>
                    <h4>Face Detan / Cleanup</h4>
                    <p>
                      Treat you appearance with a cleanup which suits your skin
                      type
                    </p>
                  </span>
                </div>
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-6.png")}
                    alt
                  />
                  <span>
                    <h4>Facial</h4>
                    <p>
                      A smoothing, skin cleansing regime in your own private
                      space
                    </p>
                  </span>
                </div>
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-7.png")}
                    alt
                  />
                  <span>
                    <h4>Beard / Moustache Trim</h4>
                    <p>Now always look sharp with our signature trims</p>
                  </span>
                </div>
                <div className="services col-lg-3 col-sm-5">
                  <img
                    src={require("../../assets/img/service-icons/icon-8.png")}
                    alt
                  />
                  <span>
                    <h4>Head Massage</h4>
                    <p>Relax your body and mind with our signature massage</p>
                  </span>
                </div>
              </div>
            </div>
            {/* /Row */}
          </div>
          {/* /Container */}
        </div>
        {/* Contact */}
        <div id="contact">
          <div className="contact container">
            <div className="row text-center">
              <div className="section-header text-center">
                <h2 className="title white-text">CONTACT US</h2>
              </div>
              <div className="col-lg-4 offset-lg-0 col-md-10 offset-md-1">
                <div className="contact-boxes salon-content-boxes">
                  <div className="contact-box">
                    <span className="salon-icon-box">
                      <a
                        href="https://www.google.com/maps/place/19%C2%B006'28.9%22N+72%C2%B049'53.6%22E/@19.1080278,72.8293669,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xb8e4f123a79eee42!8m2!3d19.1080278!4d72.8315556"
                        target="blank"
                      >
                        <i className="flaticon-location" />
                      </a>
                    </span>
                    <h4 className="title-box">Location</h4>
                    <div className="content-box salon-content-box">
                      <p>
                        Sukh Shanti, NS Road No. 8, Near Punjab National Bank,
                        Juhu, Mumbai 400049
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-0 col-md-10 offset-md-1">
                <div className="contact-boxes salon-content-boxes">
                  <div className="contact-box two">
                    <span className="salon-icon-box">
                      <a
                        href="mailto:hair2staymumbai@gmail.com"
                        className="__cf_email__"
                        data-cfemail
                      >
                        <i className="flaticon-email" />
                      </a>
                    </span>
                    <h4 className="title-box">Email</h4>
                    <div className="content-box salon-content-box">
                      <p>hair2staymumbai@gmail.com</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-0 col-md-10 offset-md-1">
                <div className="contact-boxes salon-content-boxes">
                  <div className="contact-box">
                    <span className="salon-icon-box">
                      <a href="tel:9820199966">
                        <i className="flaticon-call" />
                      </a>
                    </span>
                    <h4 className="title-box">Phone</h4>
                    <div className="content-box salon-content-box">
                      <p>+91 9820199966</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Contact */}
        {/* Footer */}
        <footer id="footer" className="sm-padding bg-dark">
          {/* Container */}
          <div className="container">
            {/* Row */}
            <div className="row">
              <div className="col-md-12">
                {/* footer logo */}
                {/* <div class="footer-logo">
						<a href="index.html"><img src="img/logo-alt.png")} alt="logo"></a>
					</div> */}
                {/* /footer logo */}
                {/* footer follow */}
                {/* <ul class="footer-follow">
						<li><a href="#"><i class="fa fa-facebook"></i></a></li>
						<li><a href="#"><i class="fa fa-twitter"></i></a></li>
						<li><a href="#"><i class="fa fa-google-plus"></i></a></li>
						<li><a href="#"><i class="fa fa-instagram"></i></a></li>
						<li><a href="#"><i class="fa fa-linkedin"></i></a></li>
						<li><a href="#"><i class="fa fa-youtube"></i></a></li>
					</ul> */}
                {/* /footer follow */}
                {/* footer copyright */}
                <div className="footer-copyright">
                  <p>
                    Copyright © 2022 Hair2Stay (A brand owned by Triorama LLP),
                    All Rights Reserved
                  </p>
                </div>
                {/* /footer copyright */}
              </div>
            </div>
            {/* /Row */}
          </div>
          {/* /Container */}
        </footer>
        {/* /Footer */}
        {/* Back to top */}
        {/* <div id="back-to-top"></div> */}
        {/* /Back to top */}
        {/* Preloader */}
        <div id="preloader">
          <div className="preloader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
        {/* /Preloader */}
        {/* jQuery Plugins */}
        <div className="container">
          {/* Modal */}
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                {/* <div class="modal-header">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
				<h4 class="modal-title"></h4>
			  </div> */}
                <div className="modal-body">
                  <p>
                    Thank you for contacting us. We will get back to you soon.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="main-btn"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalonPage;
