/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PageMeta from "../../components/page-meta/page-meta";
import SharedScripts from "../../shared-scripts";
import MailScript from "../../shared-scripts/mail-script";
import SlickSliderScript from "../../shared-scripts/slick-slider-scripts";

const HomePage = () => {
  const history = useHistory();
  useEffect(() => {
    SharedScripts.exectute();
    MailScript.exectute();
    SlickSliderScript.exectute();
  }, []);
  return (
    <>
      <PageMeta title="" description="" />
      <div>
        {/* Header */}
        <header id="home">
          {/* Background Image */}
          <div
            className="bg-img"
            style={{
              backgroundImage: `url(${require("../../assets/img/bg-image.jpg")})`,
            }}
          ></div>
          <div
            className="bg-img-mob"
            style={{
              backgroundImage: `url(${require("../../assets/img/bg-image-mob.jpg")})`,
            }}
          >
            {/* <div class="overlay"></div> */}
          </div>
          {/* /Background Image */}
          {/* Nav */}
          <nav id="nav" className="navbar nav-transparent">
            <div className="container">
              <div className="navbar-header">
                {/* Logo */}
                <div className="navbar-brand">
                  <a href="/">
                    <img
                      className="logo"
                      src={require("../../assets/img/icon.png")}
                      alt="logo"
                      width="40px"
                      height="auto"
                    />
                    <img
                      className="logo-alt"
                      src={require("../../assets/img/true-color-h2s-web.png")}
                      alt="logo"
                      width="150px"
                      height="auto"
                    />
                  </a>
                </div>
                {/* /Logo */}
                {/* Collapse nav button */}
                <div className="nav-collapse">
                  <span />
                </div>
                {/* /Collapse nav button */}
              </div>
              {/*  Main navigation  */}
              <ul className="main-nav nav navbar-nav navbar-right">
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#about">Gallery</a>
                </li>
                {/* <li><a href="#portfolio">Portfolio</a></li>
					<li><a href="#service">Services</a></li>
					<li><a href="#pricing">Prices</a></li>
					<li><a href="#team">Team</a></li>
					<li class="has-dropdown"><a href="#blog">Blog</a>
						<ul class="dropdown">
							<li><a href="blog-single.html">blog post</a></li>
						</ul>
					</li> */}
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <button
                  className="service-btn-nav"
                  onClick={(e) => history.replace("/salon")}
                >
                  Salon Services
                </button>
              </ul>
              {/* /Main navigation */}
            </div>
          </nav>
          {/* /Nav */}
          <ul className="ct-socials">
            <li>
              <a href="https://www.facebook.com/hair2stay.in/" target="_blank">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/hair2stay.in/" target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
          {/* home wrapper */}
          <div className="home-wrapper">
            <div className="container">
              <div className="row">
                {/* home content */}
                <div className="col-md-10">
                  <div className="home-content">
                    <img
                      className="logo-sm"
                      src={require("../../assets/img/true-color-h2s-web.png")}
                      alt
                    />
                    <h1 className="blue-text main-heading">
                      Hair Restoration Lounge
                    </h1>
                    <div className="row col-web">
                      <div className="col-lg-9 col-md-9 text-center">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              className="icon-desc"
                              src={require("../../assets/img/chair.png")}
                              alt
                            />
                            <br />
                            <span className="blue-text icon-text">
                              Personalized Luxurious Rooms
                            </span>
                          </div>
                          <div className="col-md-3">
                            <img
                              className="icon-desc"
                              src={require("../../assets/img/sanitiser.png")}
                              alt
                            />
                            <br />
                            <span className="blue-text icon-text">
                              Excellent Hygiene
                            </span>
                          </div>
                          <div className="col-md-3">
                            <img
                              className="icon-desc"
                              src={require("../../assets/img/security.png")}
                              alt
                            />
                            <br />
                            <span className="blue-text icon-text">
                              Customer’s Privacy &amp; Discretion
                            </span>
                          </div>
                          <div className="col-md-3">
                            <img
                              className="icon-desc"
                              src={require("../../assets/img/hair-cut.png")}
                              alt
                            />
                            <br />
                            <span className="blue-text icon-text">
                              Expert Hairstylists
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row col-mob">
                      <div className="col-sm-6">
                        <img
                          className="icon-desc"
                          src={require("../../assets/img/chair.png")}
                        />
                        <span className="blue-text icon-text-sm">
                          Personalized Luxurious Rooms
                        </span>
                      </div>
                      <div className="col-sm-6">
                        <img
                          className="icon-desc"
                          src={require("../../assets/img/sanitiser.png")}
                          alt
                        />
                        <span className="blue-text icon-text-sm">
                          Excellent Hygiene
                        </span>
                      </div>
                      <div className="col-sm-6">
                        <img
                          className="icon-desc"
                          src={require("../../assets/img/security.png")}
                          alt
                        />
                        <span className="blue-text icon-text-sm">
                          Customer’s Privacy &amp; Discretion
                        </span>
                      </div>
                      <div className="col-sm-6">
                        <img
                          className="icon-desc"
                          src={require("../../assets/img/hair-cut.png")}
                          alt
                        />
                        <span className="blue-text icon-text-sm">
                          Expert Hairstylists
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <button class="white-btn">Get Started!</button> */}
                  <button
                    className="main-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "#contact";
                    }}
                  >
                    CONTACT US
                  </button>
                </div>
              </div>
              {/* /home content */}
            </div>
          </div>
          {/* /home wrapper */}
        </header>
        {/* /Header */}
        <div id="about" className="section md-padding">
          {/* Container */}
          <div className="container">
            {/* Row */}
            <div className="row">
              {/* Section header */}
              <div className="section-header text-center">
                <h2 className="title">GALLERY</h2>
              </div>
              {/* /Section header */}
              <div className="sliderWrapper">
                <div className="slider">
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161063/Hair2Stay%20Website/1_ivf2ei.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161063/Hair2Stay%20Website/1_ivf2ei.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161076/Hair2Stay%20Website/2_copu2o.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161076/Hair2Stay%20Website/2_copu2o.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161063/Hair2Stay%20Website/3_rtw2sm.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161063/Hair2Stay%20Website/3_rtw2sm.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161068/Hair2Stay%20Website/4_qli8et.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161068/Hair2Stay%20Website/4_qli8et.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161073/Hair2Stay%20Website/5_t8kxjt.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161073/Hair2Stay%20Website/5_t8kxjt.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161070/Hair2Stay%20Website/6_ei6ocl.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161070/Hair2Stay%20Website/6_ei6ocl.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161068/Hair2Stay%20Website/7_cwiabe.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161068/Hair2Stay%20Website/7_cwiabe.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161069/Hair2Stay%20Website/8_aooqvx.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161069/Hair2Stay%20Website/8_aooqvx.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161066/Hair2Stay%20Website/9_sc5qim.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161066/Hair2Stay%20Website/9_sc5qim.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161072/Hair2Stay%20Website/10_xwu3zv.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161072/Hair2Stay%20Website/10_xwu3zv.jpg" />
                    </div>
                  </a>
                  <a
                    className="slide-img"
                    href="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161069/Hair2Stay%20Website/12_v1mxic.jpg"
                  >
                    <div>
                      <img src="https://res.cloudinary.com/psdesignz4u/image/upload/v1652161069/Hair2Stay%20Website/12_v1mxic.jpg" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            {/* /Row */}
          </div>
          {/* /Container */}
        </div>
        {/* Contact */}
        <div id="contact">
          <div className="contact container">
            <div className="row">
              <div className="col-lg-4 offset-lg-0 col-md-10 offset-md-1">
                <div className="contact-boxes">
                  <div className="contact-box">
                    <span className="icon-box">
                      <a
                        href="https://www.google.com/maps/place/19%C2%B006'28.9%22N+72%C2%B049'53.6%22E/@19.1080278,72.8293669,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xb8e4f123a79eee42!8m2!3d19.1080278!4d72.8315556"
                        target="blank"
                      >
                        <i className="flaticon-location" />
                      </a>
                    </span>
                    <h4 className="title-box">Location</h4>
                    <div className="content-box">
                      <p>
                        Sukh Shanti, NS Road No. 8, Near Punjab National Bank,
                        Juhu, Mumbai 400049
                      </p>
                    </div>
                  </div>
                  <div className="contact-box two">
                    <span className="icon-box">
                      <a
                        href="mailto:hair2staymumbai@gmail.com"
                        className="__cf_email__"
                        data-cfemail
                      >
                        <i className="flaticon-email" />
                      </a>
                    </span>
                    <h4 className="title-box">Email</h4>
                    <div className="content-box">
                      <p>hair2staymumbai@gmail.com</p>
                    </div>
                  </div>
                  <div className="contact-box">
                    <span className="icon-box">
                      <a href="tel:9820199966">
                        <i className="flaticon-call" />
                      </a>
                    </span>
                    <h4 className="title-box">Phone</h4>
                    <div className="content-box">
                      <p>+91 9820199966</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 offset-lg-0 col-md-10 offset-md-1">
                <div className="main-title">
                  {/* <h3>Get In Touch</h3> */}
                  <h2>book your free consultation</h2>
                </div>
                <form className="contact-form text-center" id="contactForm">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          id="name"
                          name="name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          id="phone"
                          name="phone"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                  {/* <div class="form-group">
							<input type="text" class="form-control" placeholder="Subject" id="subject" name="subject" required>
						</div> */}
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Your Message"
                      id="message"
                      name="message"
                      required
                      defaultValue={""}
                    />
                  </div>
                  {/* Button Send Message */}
                  <button
                    className="contact-btn"
                    type="submit"
                    name="send"
                    id="mailSubmitBtn"
                  >
                    Send Message
                  </button>
                  {/* Form Message  */}
                  {/* <div class="form-message text-center"><span></span></div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Contact */}
        {/* Footer */}
        <footer id="footer" className="sm-padding bg-dark">
          {/* Container */}
          <div className="container">
            {/* Row */}
            <div className="row">
              <div className="col-md-12">
                {/* footer logo */}
                {/* <div class="footer-logo">
						<a hrrequireef.=/../assets"index.html"><img src={("img/logo-alt.png" alt="logo"></a>)}
					</div> */}
                {/* /footer logo */}
                {/* footer follow */}
                {/* <ul class="footer-follow">
						<li><a href="#"><i class="fa fa-facebook"></i></a></li>
						<li><a href="#"><i class="fa fa-twitter"></i></a></li>
						<li><a href="#"><i class="fa fa-google-plus"></i></a></li>
						<li><a href="#"><i class="fa fa-instagram"></i></a></li>
						<li><a href="#"><i class="fa fa-linkedin"></i></a></li>
						<li><a href="#"><i class="fa fa-youtube"></i></a></li>
					</ul> */}
                {/* /footer follow */}
                {/* footer copyright */}
                <div className="footer-copyright">
                  <p>
                    Copyright © 2022 Hair2Stay (A brand owned by Triorama LLP),
                    All Rights Reserved
                  </p>
                </div>
                {/* /footer copyright */}
              </div>
            </div>
            {/* /Row */}
          </div>
          {/* /Container */}
        </footer>
        {/* /Footer */}
        {/* Back to top */}
        {/* <div id="back-to-top"></div> */}
        {/* /Back to top */}
        {/* Preloader */}
        <div id="preloader">
          <div className="preloader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
        {/* /Preloader */}
      </div>
    </>
  );
};

export default HomePage;
