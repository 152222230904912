import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import HomePage from "./pages/home-page/home-page";
import SalonPage from "./pages/salon-page/salon-page";
import LandingPage from "./pages/landing-page/landing-page";
import { persistor, store } from "./redux/store";
import Error404 from "./pages/error-not-found/error-not-found";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div></div>} persistor={persistor}>
        <Router>
          <div>
            <Switch>
              <Route path="/salon" component={SalonPage} />
              <Route path="/landing-page" component={LandingPage} />
              <Route exact path="/" component={HomePage} />
              <Route path="*" component={Error404} />
              {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
            </Switch>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
